import React from 'react';
import Icon from '../icon';
import classNames from 'classnames';
import Text from '../text';

type Props = {
  icon: React.ComponentProps<typeof Icon>['name'];
  label: string;
  value: number;
  action?: React.ReactNode;
  truncate?: boolean;
};

function ButtonAction({
  icon,
  label,
  value,
  action,
  truncate = false,
  ...props
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const defaultClassName =
    'bg-gray-75 dark:bg-white/[.06] hover:bg-gray-200 dark:hover:bg-gray-800 border border-gray-200 dark:border-gray-800 hover:border-gray-300 dark:hover:border-gray-900 rounded flex items-center justify-between px-3 py-2 w-full transition-all ease-in-out duration-200';
  const className = classNames(defaultClassName, props.className);
  const textClassName = truncate ? 'truncate max-w-52' : '';

  return (
    <div {...props} role="button" className={className}>
      <div className="flex items-center gap-3">
        <Icon name={icon} size={16} />
        <Text
          type="body"
          size="large"
          color="text-gray-500"
          className={textClassName}
          title={truncate ? label : undefined}
        >
          {label}
        </Text>
        <Text type="body" size="large">
          {value}
        </Text>
      </div>
      {!!action && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {action}
        </div>
      )}
    </div>
  );
}

export default ButtonAction;
