import JsonView from 'react18-json-view';

type Props = {
  log: Record<string, any>;
  customizeCopy?: (copy: any) => void;
};

function JsonPrint({ log, ...props }: Props) {
  return (
    <JsonView
      src={log}
      style={{
        paddingTop: '2rem',
        paddingBottom: '2rem',
        fontSize: '14px',
      }}
      {...props}
    />
  );
}

export default JsonPrint;
