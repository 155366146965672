import { Themes } from '@blastradius/ui/themes';
import React from 'react';
import TooltipStyles from '../tooltip/tooltip-styles';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import useTheme from '../../hooks/use-theme';

const tooltipThemeMapper: {
  [key in Themes]: React.ComponentProps<typeof ReactTooltip>['variant'];
} = {
  light: 'dark',
  dark: 'light',
};

type Props = {
  length?: number;
  value: React.ReactNode;
  tooltip?: React.ComponentProps<typeof ReactTooltip>;
  withTooltip?: boolean;
};

function setEndTruncation(value: string, maxLength: number) {
  if (!value) {
    return '-';
  }

  if (value.length <= maxLength) {
    return value;
  }

  return `${value.substring(0, maxLength)}...`;
}

const EndTruncation: React.FC<Props> = ({
  value,
  length = 30,
  tooltip = undefined,
  withTooltip = true,
}) => {
  const tooltipId = Math.random().toString(36).slice(2);
  const { theme } = useTheme();
  const tooltipStyle = TooltipStyles(theme);
  const [hasTooltip] = React.useState(
    (value?.toString() as string)?.length > length,
  );

  return (
    <span
      className="truncate"
      data-tooltip-id={tooltipId}
      data-tooltip-content={value}
    >
      {setEndTruncation(value as string, length)}
      {withTooltip && hasTooltip && (
        <ReactTooltip
          {...tooltip}
          id={tooltipId}
          place={tooltip?.place || 'bottom'}
          variant={tooltipThemeMapper[theme]}
          style={{
            backgroundColor: tooltipStyle['data-background-color'],
            zIndex: 50,
          }}
          arrowColor={tooltipStyle['data-background-color']}
        />
      )}
    </span>
  );
};

export default EndTruncation;
